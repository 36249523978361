<template>
	<transition name="scale">
		<div class="a-plane-popup" :style="markerInfoStyle" v-if='value'>
			<div class="a-plane-popup__content" v-click-outside="closeDialog">
				<div class="a-plane-popup__close" @click.stop="closeDialog">
					<v-icon icon="cross" size="12"></v-icon>
				</div>
				<p class='a-plane-popup-title'>{{$t("account.offers.flights.place")}} {{card.rowNumber}}{{card.number}}</p>
				<div class="row">
					<div class="col">
						<p class='a-plane-popup-text'>{{$t("account.offers.flights.passenger")}} {{passengerId}}</p>
					</div>
					<div class="col-auto">
						<p class='a-plane-popup-text'>{{$helpers.amount(card.price.amount, card.price.currency)}}</p>
					</div>
				</div>
				<template v-if='thisSeat'>
					<v-button block xxsmall outline color='green-persian' class='a-plane-popup-button'
						@click='$emit("remove-place", card)'
					>{{$t("account.offers.flights.delete_place")}}</v-button>
				</template>
				<template v-else>
					<v-button block xxsmall color='green-persian white--text' class='a-plane-popup-button'
						@click='$emit("add-place", card)'
					>{{thisPlane ? $t("account.offers.flights.edit_place") : $t("account.offers.flights.add_place")}}</v-button>
				</template>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		data: () => ({
			card: {},
			changeCard: false
		}),
		props: {
			value: {
				type: Boolean,
				default: false
			},
			cardActive: Object,
			markerInfoStyle: Object,
			passengerId: Number,
			thisSeat: Boolean,
			thisPlane: Boolean
		},
		methods: {
			closeDialog() {
				if(this.changeCard) return;
				this.$emit('input');
			}
		},
		created() {
			this.card = this.cardActive
		},
		watch: {
			cardActive() {
				this.changeCard = !(this.card.rowNumber === this.cardActive.rowNumber && this.card.number === this.cardActive.number);
				this.card = this.cardActive;
				setTimeout(() => this.changeCard = false, 1)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-plane {
		&-popup {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			transition: all $transition;
			@include maxw($grid-breakpoints-sm - 1) {
				position: fixed;
				top: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 115px;
			}
			@include maxw($grid-breakpoints-xs - 1) {
				bottom: 85px;
			}
			&__content {
				width: 290px;
				padding: 15px;
				background-color: $white;
				border-radius: $border-radius * 2;
				box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
				@include minw($grid-breakpoints-sm) {
					width: 340px;
					padding: 30px 25px 25px;
				}
				@include maxw($grid-breakpoints-sm - 1) {
					margin: auto;
				}
			}
			&__close {
				position: absolute;
				right: 15px;
				top: 15px;
				cursor: pointer;
				z-index: 1;
				svg path {
					fill: rgba($black, .6);
					transition: fill $transition;
				}
				&:hover {
					svg path {
						fill: $green;
					}
				}
			}
			&-title {
				font-size: 18px;
				font-weight: 700;
				color: $green-persian;
				margin-bottom: 5px;
				@include minw($grid-breakpoints-sm) {
					font-size: 22px;
				}
			}
			&-text {
				font-size: 13px;
				font-weight: 400;
				@include minw($grid-breakpoints-sm) {
					font-size: 16px;
				}
			}
			&-button {
				margin-top: 20px;
				@include minw($grid-breakpoints-sm) {
					margin-top: 25px;
				}
			}
		}
	}
</style>
