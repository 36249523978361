<template>
	<div class='a-offer-airplane-plan' v-if='seatMap'>
		<div class="a-plane">
			<div class="a-plane-wrap">
				<div class="a-plane-row a-plane-flex a-plane-header">
					<div class='a-plane-flex' v-for='(count, i) in splitArr(seatMap.column, "characteristics").length' :key='i'>
						<div class="a-plane-row-group a-plane-flex">
							<div class='a-plane-cell' v-for='(column, i) in splitArr(seatMap.column, "characteristics")[i]' :key='i'>
								<span class="a-plane-flex a-plane-cell-text">{{column.column}}</span>
							</div>
						</div>
						<div class="a-plane-aisle a-plane-flex" v-if='splitArr(seatMap.column, "characteristics").length - i > i'></div>
					</div>
				</div>

				<div v-for='(row, index) in seatMap.row' :key='index'>
					<div class='a-plane-exitrowind' v-if='row.seatAttributes.exitrowind == "true"'>
						<span class='a-plane-exitrowind-cell'>{{$t("account.exit")}}</span>
						<span class='a-plane-exitrowind-cell'>{{$t("account.exit")}}</span>
					</div>
					<div class="a-plane-row a-plane-flex">
						<div class='a-plane-flex' v-for='(count, i) in splitArr(row.seat, "isAisleSeat", true).length' :key='i'>
							<div class="a-plane-row-group a-plane-flex">
								<div class='a-plane-cell' v-for='(seat, i) in splitArr(row.seat, "isAisleSeat", true)[i]' :key='i'>
								<template v-if='!seat.isAvailable'>
									<span class="a-plane-cell-button a-plane-flex disabled">
										<v-icon icon='cross' size='26' />
									</span>
								</template>
								<template v-else>
									<button class="a-plane-cell-button a-plane-flex white--text"
										:style='`background-color: ${colorSeat(seat)}`'
										:class='{"active": seats.some(el =>
											el.segmentKey == segmentKey &&
											el.row == row.rowNumber &&
											el.column == seat.number
										) || cardActive.rowNumber == row.rowNumber && cardActive.number == seat.number}'
										@click='onClickPlace($event, {rowNumber: row.rowNumber, ...seat})'
									>
										{{row.rowNumber}}{{seat.number}}
									</button>
								</template>
								</div>
							</div>
							<div class="a-plane-aisle a-plane-flex" v-if='splitArr(row.seat, "isAisleSeat", true).length - i > i'>
								<p class='a-plane-aisle-text'>{{row.rowNumber}}</p>
							</div>
						</div>
					</div>
				</div>

				<a-airplane-plan-details
					v-model='openCard'
					@input='closeDialog()'
					:cardActive='cardActive'
					:markerInfoStyle='markerInfoStyle'
					:passengerId='passengerId'
					@add-place='addPlace($event)'
					@remove-place='removePlace($event)'
					:thisSeat='seats.some(el =>
						el.segmentKey == segmentKey &&
						el.row == cardActive.rowNumber &&
						el.column == cardActive.number
					)'
					:thisPlane='seats.some(el =>
						el.passengerId == flightsActive.passengerId &&
						el.passengerNumber == flightsActive.passengerNumber &&
						el.segmentKey == segmentKey
					)'
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import AAirplanePlanDetails from '@/views/account/offers/flights/offer/airplane-plan-details';

	export default {
		data: () => ({
			openCard: false,
			cardActive: {},
			top: 0,
			left: 0,
			right: 0,
			seats: []
		}),
		props: ['seatMap', 'flightsActive', 'segmentKey', 'passengerId', 'initSeat'],
		components: {
			AAirplanePlanDetails
		},
		computed: {
			markerInfoStyle() {
				return {
					top: this.top,
					left: this.left,
					right: this.right
				}
			}
		},
		created() {
			this.seats = this.initSeat;

			window.addEventListener("resize", () => {
				let self = this;
				self.openCard = false
			}, false );
		},
		methods: {
			addPlace($event) {
				this.seats = this.seats.filter(el =>
					!(el.passengerId == this.flightsActive.passengerId
					&& el.passengerNumber == this.flightsActive.passengerNumber
					&& el.segmentKey == this.segmentKey)
				);

				this.seats = [...this.seats, {
					passengerId: this.flightsActive.passengerId,
					passengerNumber: this.flightsActive.passengerNumber,
					segmentKey: this.segmentKey,
					row: $event.rowNumber,
					column: $event.number,
					price: $event.price
				}]

				this.closeDialog();
				this.$emit("next-tourist");
			},
			removePlace($event) {
				this.seats = this.seats.filter(el =>
					!(el.segmentKey == this.segmentKey
					&& el.row == $event.rowNumber
					&& el.column == $event.number)
				);

				this.closeDialog();
				this.$emit("next-tourist");
			},
			closeDialog() {
				this.$emit("update-seat", this.seats);
				this.openCard = false;
				this.cardActive = {};
			},
			splitArr(arr, key, isBoolean, r = [], index = 0) {
				arr.forEach((el, i) => {
					if(arr[i - 1] && (isBoolean ? arr[i - 1][key] === true && el[key] === true : arr[i - 1][key] === el[key])) {
						index = index + 1;
					}
					if(r[index]) {
						r[index].push(el)
					} else {
						r.push([el])
					}
				})

				return r
			},
			onClickPlace(event, item) {
				this.openCard = true;
				this.cardActive = item;
				let boundy = event.target.getBoundingClientRect();
				let plane = document.querySelector('.a-plane').getBoundingClientRect();
				let containerScroll = document.querySelector('.a-offer-airplane-scroll');

				if((containerScroll.offsetWidth - boundy.left - 340) < 0) {
					this.right = plane.right - boundy.right + 'px';
					this.left = 'inherit';
				} else {
					this.left = boundy.left - plane.left + 'px';
					this.right = 'inherit';
				}

				let bottomBoundy = containerScroll.getBoundingClientRect().height - boundy.top - boundy.height < 0 ? boundy.height + 177 : 0;
				this.top = boundy.top - containerScroll.getBoundingClientRect().top + containerScroll.scrollTop - bottomBoundy + 'px';
			},
			colorSeat(seat) {
				if(seat.isComfort) {
					return '#D7617D'
				} else if(seat.isExitRowSeat) {
					return '#BB6BD9'
				} else if(seat.isPreferred) {
					return '#F6BD4C'
				}
				return '#00B796'
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-airplane-plan {
		flex: 1 1 100%;
		display: grid;
		justify-content: center;
		padding: 20px 10px 100px;
		@include minw($grid-breakpoints-sm) {
			padding: 60px * .8 45px * .8;
		}
		@include minw($grid-breakpoints-lg) {
			padding: 60px 45px;
		}
	}

	.a-plane {
		&-wrap {
			padding: 40px 10px;
			background: $white;
			border: 2px solid rgba(0, 183, 150, 0.2);
			border-radius: 50px;
			@include minw($grid-breakpoints-xs) {
				padding-left: 20px;
				padding-right: 20px;
			}
			@include minw($grid-breakpoints-sm) {
				padding: 85px * .8 35px * .8 65px * .8;
				border-radius: 100px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				padding: 85px 35px 65px;
				border-radius: 100px;
			}
		}
		&-flex {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&-row {
			margin: 0 -1px;
			@include minw($grid-breakpoints-xs) {
				margin: 0 -2px;
			}
			&-group {
				margin: 0 -1px;
				@include minw($grid-breakpoints-xs) {
					margin: 0 -2px;
				}
			}
		}
		&-cell {
			padding: 3px 1px;
			@include minw($grid-breakpoints-xs) {
				padding: 6px 2px;
			}
			&-text,
			&-button {
				width: 32px;
				height: 32px;
				font-size: 12px;
				font-weight: 400;
				@include minw($grid-breakpoints-xs) {
					width: 66px * .6;
					height: 66px * .6;
					font-size: 14px;
				}
				@include minw($grid-breakpoints-sm) {
					width: 66px * .8;
					height: 66px * .8;
					font-size: 16px;
				}
				@include minw($grid-breakpoints-lg) {
					width: 66px;
					height: 66px;
				}
			}
			&-button {
				border-radius: 5px;
				transition: background-color $transition;
				&:hover,
				&.active {
					color: $white !important;
					background-color: $cyprus !important;
				}
				&.disabled {
					pointer-events: none;
					color: rgba($cyprus, .3);
					background-color: rgba($cyprus, .05);
				}
			}
		}
		&-aisle {
			width: 16px;
			@include minw($grid-breakpoints-xs) {
				width: 54px * .6;
			}
			@include minw($grid-breakpoints-sm) {
				width: 54px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				width: 54px;
			}
			&-text {
				font-size: 10px;
				font-weight: 400;
				@include minw($grid-breakpoints-xs) {
					font-size: 14px;
				}
				@include minw($grid-breakpoints-sm) {
					font-size: 16px;
				}
			}
		}
		&-header {
			border: 1px solid rgba($cyprus, .1);
			border-radius: 5px;
			margin-bottom: 30px;
			@include minw($grid-breakpoints-xs) {
				margin-bottom: 57px;
			}
			.a-plane {
				&-cell {
					padding-top: 0;
					padding-bottom: 0;
				}
				&-row-group {
					&:before {
						content: '';
						position: absolute;
						top: 100%;
						right: 2px;
						left: 2px;
						margin-top: 10px;
						height: 5px;
						background-color: rgba($green-persian, .15);
						border-radius: 2px;
						@include minw($grid-breakpoints-xs) {
							margin-top: 15px;
							height: 10px;
						}
					}
				}
			}
		}
		&-exitrowind {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-left: -10px;
			margin-right: -10px;
			@include minw($grid-breakpoints-xs) {
				margin-left: -20px;
				margin-right: -20px;
			}
			@include minw($grid-breakpoints-sm) {
				margin-left: -35px * .8;
				margin-right: -35px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin-left: -35px;
				margin-right: -35px;
			}
			&-cell {
				color: rgba($cyprus, .7);
				display: flex;
				align-items: center;
				font-size: 10px;
				font-weight: 400;
				@include minw($grid-breakpoints-xs) {
					font-size: 14px;
				}
				@include minw($grid-breakpoints-sm) {
					font-size: 16px;
				}
				&:first-child {
					&:before {
						content: '';
						width: 5px;
						height: 35px;
						background-color: rgba($cyprus, .7);
						margin-right: 15px;
					}
				}
				&:last-child {
					&:after {
						content: '';
						width: 5px;
						height: 35px;
						background-color: rgba($cyprus, .7);
						margin-left: 15px;
					}
				}
			}
		}
	}
</style>
