<template>
	<div ref='a-offer-airplane' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-top">
			<div class='a-offer-airplane' v-if='value'>
				<div class='app-container a-offer-airplane-container' v-click-outside="closeDialog">
					<div class='a-offer-airplane-wrap'>
						<div class="a-offer-airplane-header">
							<div class="a-offer-airplane-topline">
								<div class='a-offer-airplane-button-close ml-auto' @click='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
							<div class='a-offer-airplane-selects' v-if='!loading && !errors && segment'>
								<div class="row">
									<div class="col-6">
										<a-select
											:placeholder='$t("account.booking.content.choose")'
											:options='segments'
											v-model='segmentKey'
											class='a-select-count'
											item-value='segmentKey'
											isDisabledSegments
										>
											<template v-slot:field>
												<div class='flex a-card-clock-flex desktop-hide'>
													<div class='flex'>
														<div class='a-card-logo'>
															<img :src="`https://api.bravo-travel.online/api${segment.segmentInfo.AirlineLogo}`" />
														</div>
														<div>
															<p class='a-card-title'>{{$moment(segment.segmentInfo.DepartureDT).format("HH:mm")}}</p>
															<p class='a-card-sub-title' v-tooltip.top="segment.segmentInfo.OriginLocName" style='cursor: help'>{{segment.segmentInfo.OriginLoc}}</p>
														</div>
														<div class='a-card-dots'>
															<span class='a-card-dot'></span>
															<span class='a-card-dot' v-for="(dot, i) in segment.segmentInfo.Stops" :key='i'></span>
															<span class='a-card-dot'></span>
														</div>
														<div>
															<p class='a-card-title last'>{{$moment(segment.segmentInfo.ArrivalDT).format("HH:mm")}}
																<span class='a-card-title-sup' v-if='$helpers.diffDays(segment.segmentInfo.DepartureDT, segment.segmentInfo.ArrivalDT) > 0'
																	v-tooltip.top="`${$t('account.details.flights.arrival_in')} ${$moment(segment.segmentInfo.ArrivalDT).format('ddd, MMM DD')}`" style='cursor: help'
																>+{{$helpers.diffDays(segment.segmentInfo.DepartureDT, segment.segmentInfo.ArrivalDT)}}</span>
															</p>
															<p class='a-card-sub-title' v-tooltip.top="segment.segmentInfo.DestinationLocName" style='cursor: help'>{{segment.segmentInfo.DestinationLoc}}</p>
														</div>
													</div>
												</div>
												<div class='a-select__dropdown--item-flight desktop-show'>
													<span>{{segment.segmentInfo.OriginLoc}}</span>
													<v-icon icon='arrow-right' size='14' class='a-select__dropdown--item-icon-flight' />
													<span>{{segment.segmentInfo.DestinationLoc}}</span>
												</div>
											</template>
											<template v-slot:count>
												<p class='a-select-count-text'>{{segments.findIndex(el => el.segmentKey === segmentKey) + 1}}/{{segments.length}}</p>
											</template>
											<template v-slot:dropdown-list-item='{option}'>
												<div class='a-select__dropdown--item-flight'>
													<span class='desktop-hide'>{{option.segmentInfo.OriginLocName}}</span>
													<span class='desktop-show'>{{option.segmentInfo.OriginLoc}}</span>
													<v-icon icon='air' size='14' class='a-select__dropdown--item-icon-flight' />
													<span class='desktop-hide'>{{option.segmentInfo.DestinationLocName}}</span>
													<span class='desktop-show'>{{option.segmentInfo.DestinationLoc}}</span>
												</div>
											</template>
										</a-select>
									</div>
									<div class="col-6">
										<a-select
											:placeholder='$t("account.booking.content.choose")'
											:options='details.passengers'
											v-model='passengerId'
											class='a-select-count'
										>
											<template v-slot:field>
												<p>{{passengerActive.firstName}} {{passengerActive.lastName}}</p>
											</template>
											<template v-slot:count>
												<p class='a-select-count-text'>{{details.passengers.findIndex(el => el.id === passengerId) + 1}}/{{details.passengers.length}}</p>
											</template>
											<template v-slot:dropdown-list-item='{option}'>
												<span>{{option.firstName}} {{option.lastName}}</span>
											</template>
										</a-select>
									</div>
								</div>
							</div>
						</div>

						<div class="a-offer-airplane-scroll">
							<div class='a-offer-airplane-content'
								:style='seatMap ? "" : "align-items: center"'
							>
								<div class='a-offer-airplane-loading' v-if='loading'>
									<trips-preloader
										name='flights'
										animation='flights'
										:title="$t('account.preloader.loading_seats')"
									/>
								</div>
								<div class='a-offer-airplane-loading' v-else-if='errors'>
									<trips-preloader
										name='flights'
										animation='flights'
										:title="$t('account.preloader.choice_of_seats_on_this_flight_is_not_available')"
										:animationDisabled='true'
									/>
								</div>

								<div class='a-offer-airplane-loading' v-else-if='!segment'>
									<trips-preloader
										name='flights'
										animation='flights'
										:title='`${$t("account.offers.choose_place.choose_place")} ${$t("account.offers.choose_place.not_available_for_this_flight").toLowerCase()}`'
										:animationDisabled='true'
									/>
								</div>

								<template v-else-if='seatMap'>
									<a-airplane-info
										class='desktop-hide'
										:seatMap='seatMap'
									/>

									<a-airplane-plan
										:seatMap='seatMap'
										:initSeat='initSeat'
										:flightsActive='flightsActive'
										:segmentKey='segmentKey'
										@update-seat='seats = $event'
										@next-tourist='nextTourist()'
										:passengerId='details.passengers.findIndex(el => el.id === passengerId) + 1'
									/>
								</template>
							</div>
						</div>

						<div class='a-offer-airplane-footer' v-if='!errors && segment'>
							<div class='a-offer-airplane-footer-content mobile-hide'>
								<div class='a-offer-airplane-footer-content-logo'>
									<span class='skeleton' :style='`width: 100%; height: 100%`' v-if='loading' />
									<img class='a-offer-airplane-footer-content-img' :src="`https://api.bravo-travel.online.club/api${segment.segmentInfo.AirlineLogo}`" v-else />
								</div>
								<p class='a-offer-airplane-footer-content-text' v-if='loading'><span class='skeleton' :style='`width: 125px; height: 19px`' /></p>
								<p class='a-offer-airplane-footer-content-text' v-else><span class='active'>{{segment.segmentInfo.AirlineCode}}{{segment.segmentInfo.AirFlightNumber}}</span> <span class='dot'>•</span> {{$helpers.timeName($moment(segment.segmentInfo.ArrivalDT).diff($moment(segment.segmentInfo.DepartureDT), 'minutes'))}}</p>
							</div>
							<div class='a-dialog-cost-total' v-if='!loading && seats.length'>
								<p class='a-dialog-cost-total-descr'>{{$t("account.offers.flights.total_surcharge_for")}} {{seats.length}} {{$helpers.declOfNum(seats.length, $t('account.declOfNum.places'))}}:</p>
								<p class='a-dialog-cost-total-price'>{{$helpers.amount($_.sumBy(seats, (by) => Number(by.price.amount)), seats[0].price.currency)}}</p>
							</div>
							<div class='mr-auto'></div>
							<div class='a-offer-airplane-footer-action'>
								<span class='skeleton' :style='`width: 178px; height: 47px`' v-if='loading' />
								<v-button xxsmall color="green white--text"
									@click='nextTrip'
									v-else
								>{{segmentKey === isDataSegments[isDataSegments.length - 1].segmentKey ? $t("account.buttons.save") : $t("account.offers.flights.next_flight")}}</v-button>
							</div>
						</div>

						<div class='a-offer-airplane-cost desktop-show' v-if='!loading && !errors && seatMap'>
							<div class='a-offer-airplane-cost-button' @click='openCost = true'>
								<v-icon icon='menu' size='12' />
								<span>{{$t("account.offers.flights.cost_seats")}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>

		<v-dialog :value='openCost' :width='450' class='a-dialog-cost' class-content='border-radius a-dialog-cost-wrap' :showCloseCross='false' @close-dialog="openCost = false">
			<div class="a-dialog-cost-header">
				<p class='a-dialog-cost-header-title'>{{$t("account.offers.flights.cost_seats")}}</p>
				<div class='a-dialog-cost-header-button ml-auto' @click.stop='openCost = false'>
					<v-icon icon='cross' size='17' />
				</div>
			</div>
			<div class='a-dialog-cost-content'>
				<a-airplane-info
					class='desktop-show'
					:seatMap='seatMap'
					v-if='seatMap'
				/>
			</div>
			<div class='a-dialog-cost-footer'>
				<div class='a-dialog-cost-footer-col'>
					<v-button block small outline color='green-persian' @click.stop="openCost = false">{{$t('account.buttons.close')}}</v-button>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import AAirplaneInfo from '@/views/account/offers/flights/offer/airplane-info';
	import AAirplanePlan from '@/views/account/offers/flights/offer/airplane-plan';
	import { flightSeatMap } from '@/services/request';
	import TripsPreloader from '@/views/account/search/components/preloader';

	export default {
		data: () => ({
			loading: false,
			errors: null,
			flights: [],
			segmentKey: 0,
			passengerId: null,
			openCost: false,
			seats: []
		}),
		props: ['value', 'details', 'initSeat'],
		components: {
			AAirplaneInfo,
			AAirplanePlan,
			TripsPreloader
		},
		computed: {
			passengerActive() {
				return this.details.passengers.find(el => el.id === this.passengerId) ?? {}
			},
			flightsActive() {
				return this.flights.find(el => el.passengerId === this.passengerId) ?? null
			},
			segments() {
				return this.flightsActive?.segments ?? null;
			},
			isDataSegments() {
				return this.segments ? this.segments.filter(el => el.seatMap.length !== 0) : null;
			},
			segment() {
				if(this.isDataSegments && this.isDataSegments.length) {
					if(this.isDataSegments.find(el => el.segmentKey === this.segmentKey)) {
						return this.isDataSegments.find(el => el.segmentKey === this.segmentKey)
					} else {
						return this.isDataSegments[0]
					}
				} else {
					return null
				}
			},
			seatMap() {
				if(this.segment?.seatMap.length === 0) return null;
				return this.segment?.seatMap ?? null
			}
		},
		created() {
			if(this.value) {
				this.flightSeatMap();
				this.passengerId = this.details.passengers[0].id;
				this.seats = this.initSeat;
			}
		},
		methods: {
			flightSeatMap() {
				this.loading = true;
				this.errors = null;

				flightSeatMap({
					storageId: this.$route.params.storageId,
					code: this.$route.params.code,
					fareCode: this.$route.params.code !== this.$route.query.offerId ? this.$route.query.offerId : null
				}).then(res => {
					this.flights = res.data;
				}).catch(err => {
					this.errors = err.response.data?.errors ?? null;
				}).finally(() => this.loading = false)
			},
			nextTourist() {
				if(this.details.passengers.length > 1) {
					let passenger = this.details.passengers.findIndex(el => el.id === this.passengerId) + 1;

					this.passengerId = this.details.passengers[passenger]?.id ?? this.details.passengers[0].id;
					if(this.details.passengers[passenger]?.id)
						return;
				}
			},
			nextTrip() {
				this.passengerId = this.details.passengers[0].id

				if(this.segmentKey === this.isDataSegments[this.isDataSegments.length - 1].segmentKey) {
					this.$emit("update-seat", this.seats);
					this.$emit("update-segments", this.isDataSegments);
					this.closeDialog();
				} else {
					let findIndexSegmentKey = this.isDataSegments.findIndex(el => el.segmentKey === this.segmentKey);
					this.segmentKey = this.isDataSegments[findIndexSegmentKey + 1].segmentKey;
				}
			},
			closeDialog() {
				if(this.openCost) return;
				this.$emit('input', false);

				this.segmentKey = 0;
			}
		},
		watch: {
			value() {
				if(this.value) {
					this.flightSeatMap();
					this.passengerId = this.details.passengers[0].id
					this.seats = this.initSeat;
					if(this.segment) {
						this.segmentKey = this.segment.segmentKey;
					}
				}
				setTimeout(() => {
					const hideEl = document.querySelector('.a-offer-airplane-scroll')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			},
			segment() {
				if(this.segment.segmentKey !== this.segmentKey) {
					this.segmentKey = this.segment.segmentKey;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-airplane {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 15;
		@include minw( $grid-breakpoints-xs ) {
			padding: 30px 0;
		}
		@include minw( $grid-breakpoints-lg ) {
			padding: 70px 0;
		}
		&-container {
			height: 100%;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding-right: 0;
				padding-left: 0;
			}
		}
		&-wrap {
			background-color: $cyan-light;
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		&-header {
			background-color: $white;
		}
		&-topline {
			padding: 16px;
			display: flex;
			border-bottom: 1px solid rgba($black, .15);
		}
		&-selects {
			border-bottom: 1px solid rgba($black, .15);
			.row {
				margin: 0;
			}
			.col-6 {
				padding: 0;
				flex: 0 0 calc(100% / 2);
				max-width: calc(100% / 2);
				&:not(:last-child) {
					border-right: 1px solid rgba($black, .15)
				}
			}
			.a-select {
				.a-dropdown__slot {
					border: none;
					min-height: 64px;
					@include minw( $grid-breakpoints-xs ) {
						min-height: 86px * .8;
					}
					@include minw( $grid-breakpoints-lg ) {
						min-height: 86px;
					}
					&:before {
						content: normal;
					}
				}
				&__dropdown--item {
					padding: 20px;
				}
			}
		}
		&-button-close {
			cursor: pointer;
			&:hover {
				color: $green-persian;
			}
		}

		&-scroll {
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			@include maxw($grid-breakpoints-xs - 1) {
				overflow-x: auto;
			}
		}

		&-content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			min-height: 100%;
		}

		&-footer {
			padding: 12px;
			background-color: $white;
			box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
			display: flex;
			align-items: center;
			@include minw($grid-breakpoints-sm) {
				padding: 29px * .8 50px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 29px 50px;
			}
			&-content {
				display: flex;
				align-items: center;
				&-logo {
					flex-shrink: 0;
					width: 50px * .8;
					height: 45px * .8;
					margin-right: 15px * .6;
					@include minw($grid-breakpoints-lg) {
						width: 50px;
						height: 45px;
						margin-right: 15px;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				&-text {
					font-size: 16px;
					font-weight: 400;
					.active {
						color: $green-persian;
					}
					.dot {
						color: rgba($cyprus, .4);
					}
				}
			}
			&-action {
				padding-bottom: env(safe-area-inset-bottom);
				.app-btn {
					min-width: 158px;
					@include minw($grid-breakpoints-sm) {
						min-width: 178px;
					}
				}
			}
		}
		&-cost {
			position: absolute;
			bottom: 85px;
			left: 10px;
			padding-bottom: env(safe-area-inset-bottom);
			@include minw($grid-breakpoints-sm) {
				bottom: 105px * 1.2;
			}
			@include minw($grid-breakpoints-md) {
				bottom: 105px;
			}
			&-button {
				font-size: 12px;
				font-weight: 400;
				padding: 12px 10px;
				color: $green-persian;
				background-color: $white;
				box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
				border-radius: 5px;
				display: flex;
				align-items: center;
				cursor: pointer;
				.app-icon {
					margin-right: 10px;
				}
			}
		}
		&-loading {
			width: 100%;
			min-height: 100%;
			display: grid;
		}
	}

	.a-select__dropdown--item {
		&-flight {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			.app-icon {
				margin: 0 8px;
			}
		}
		&.disabled {
			pointer-events: none;
			opacity: .4;
		}
	}

	.a-select__field {
		.app-icon {
			@include maxw( $grid-breakpoints-md - 1 ) {
				svg {
					stroke: rgba($black, .8);
					fill: none;
				}
			}
		}
	}

	.a-dialog-cost {
		display: none;
		@include maxw( $grid-breakpoints-md - 1 ) {
			display: block;
		}
		.app-popup {
			z-index: 20;
		}
		.backdrop {
			z-index: 15;
		}
		&-wrap {
			z-index: 5;
			padding: 0 !important;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				max-width: 100% !important;
				border-radius: 0 !important;
				display: grid;
				grid-template-rows: auto 1fr auto;
			}
		}
		&-header {
			display: flex;
			align-items: center;
			min-height: 76px;
			padding: 10px 20px;
			border-bottom: 1px solid rgba($black, .1);
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding-left: 10px;
				padding-right: 10px;
			}
			&-title {
				font-size: 20px;
				font-weight: 400;
				margin-right: 12px;
			}
			&-button {
				padding: 10px;
				cursor: pointer;
			}
		}
		&-content {
			padding: 30px 20px;
		}
		&-footer {
			padding: 30px 10px;
			display: flex;
			justify-content: flex-end;
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px 20px;
			}
			&-col {
				width: 100%;
			}
		}
		&-total {
			padding-bottom: env(safe-area-inset-bottom);
			@include minw($grid-breakpoints-xs) {
				margin-left: 80px * .8;
			}
			@include minw($grid-breakpoints-md) {
				margin-left: 80px;
			}
			&-price {
				font-size: 20px * .9;
				font-weight: 700;
				@include minw($grid-breakpoints-lg) {
					font-size: 20px;
				}
			}
			&-descr {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
			}
		}
	}
</style>
