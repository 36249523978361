<template>
	<div class='a-offer-extras-box'>
		<p class='a-offer-extras-title'>{{$t("account.offers.choose_place.choose_place")}}</p>
		<div class='a-offer-extras-card a-offer-extras-place'>
			<div class="a-offer-extras-place-wrap">
				<div class='a-offer-extras-place-icon'>
					<span v-html="require(`!html-loader!@/assets/seat.svg`)"></span>
				</div>
				<div class='a-offer-extras-place-content'>
					<template v-if='seat.length'>
						<div class='a-offer-extras-check' v-for='(segment, i) in segments' :key='i'>
							<div class='a-offer-extras-check-airplane'>
								<div class='a-card-details-info-header-content'>
									<p class='a-card-details-info-header-title'>{{segment.segmentInfo.OriginLoc}}</p>
									<v-icon icon='air' size='10' class='a-card-details-info-header-icon' />
									<p class='a-card-details-info-header-title'>{{segment.segmentInfo.DestinationLoc}}</p>
								</div>
							</div>
							<div class='a-offer-extras-check-content' v-if='seat.some(el => el.segmentKey === segment.segmentKey)'>
								<p class='a-offer-extras-check-content-place'><strong>{{seat.filter(el => el.segmentKey === segment.segmentKey).map(el => `${el.row}${el.column}`).join(', ')}}</strong></p>
							</div>
							<div class='a-offer-extras-check-content' v-else>
								<p class='a-offer-extras-check-content-place'>{{$t("account.offers.choose_place.not_available_for_this_flight")}}</p>
							</div>
						</div>
					</template>
					<template v-else>
						<ul class='a-offer-extras-list'>
							<li class='a-offer-extras-list-item'
								v-for='(item, i) in $t("account.offers.choose_place.list")' :key='i'
							>
								<v-icon icon='check' size='10' />
								{{item}}
							</li>
						</ul>
					</template>
				</div>
			</div>
			<div class='a-offer-extras-place-action'>
				<!-- <p class='a-offer-extras-place-total'>Від <strong>382 грн</strong></p> -->
				<p class='a-offer-extras-check-add' v-if='seat.length'>
					<v-icon icon='check' size='12' /> {{$t("account.offers.choose_place.add")}}
				</p>
				<v-button outline xxsmall color="green" class='a-offer-extras-place-button' @click='$emit("open-place")'>{{seat.length ? $t("account.offers.choose_place.edit_place") : $t("account.offers.choose_place.choose_a_place")}}</v-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["seat", "segments"]
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-extras {
		&-place {
			display: flex;
			flex-direction: column;
			@include minw( $grid-breakpoints-xs ) {
				flex-direction: row;
			}
			&-wrap {
				flex: 1 1 100%;
				display: flex;
				align-items: center;
			}
			&-icon {
				flex-shrink: 0;
				width: 36px;
				height: 65px;
				margin-right: 30px;
				@include minw( $grid-breakpoints-md ) {
					width: 43px * .8;
					height: 78px * .8;
					margin-right: 60px * .8;
				}
				@include minw( $grid-breakpoints-lg ) {
					width: 43px;
					height: 78px;
					margin-right: 60px;
				}
			}
			&-content {
				flex: 1 1 100%;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 12px;
				}
			}
			&-action {
				flex-shrink: 0;
			}
			&-total {
				font-size: 14px;
				font-weight: 400px;
				@include minw( $grid-breakpoints-xs ) {
					text-align: right;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-top: 35px;
				}
				strong {
					font-size: 18px;
					font-weight: 700;
				}
			}
			&-button {
				height: 40px;
				margin-top: 15px * .8;
				font-size: 15px;
				font-weight: 400;
				@include minw( $grid-breakpoints-lg ) {
					margin-top: 15px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					width: 100%;
				}
			}
		}

		&-list {
			&-item {
				font-size: 12px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba($black, .5);
				margin-bottom: 5px * .8;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
				}
				@include minw( $grid-breakpoints-lg ) {
					margin-bottom: 5px;
				}
			}
			.app-icon {
				color: rgba($cyprus, .4);
				display: inline-block;
				margin-right: 10px * .8;
				@include minw( $grid-breakpoints-lg ) {
					margin-right: 10px;
				}
			}
		}
		&-check {
			display: flex;
			align-items: flex-start;
			margin-bottom: 6px;
			&-airplane {
				flex-shrink: 0;
				width: 90px;
				.a-card-details-info-header {
					&-title {
						font-size: 13px;
					}
					&-icon {
						margin-left: 4px;
						margin-right: 4px;
					}
				}
			}
			&-content {
				margin-left: 15px;
				&-place {
					font-size: 13px;
					font-weight: 400;
					color: rgba($black, .5);
				}
			}
			&-add {
				font-size: 16px;
				font-weight: 700;
				color: #00AF85;
				@include minw( $grid-breakpoints-xs ) {
					text-align: right;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-top: 35px;
				}
				.app-icon {
					display: inline-block;
					margin-right: 5px * .8;
					@include minw( $grid-breakpoints-lg ) {
						margin-right: 5px;
					}
				}
			}
		}
	}
</style>
