<template>
	<div class="a-offer-extras-baggage-list">
		<template v-if='items.length'>
			<div class="a-offer-extras-baggage-item a-radio"
				@click='$emit("remove-baggage", baggage.find(el => items.find(item => item.id === el.baggageId) && el.passengerId === passenger.id))'
				:class='{"active": !baggage.find(el => items.find(item => item.id === el.baggageId) && el.passengerId === passenger.id)}'
			>
				<div class="a-offer-extras-baggage-item-content">
					<span class='a-radio-icon'></span>
					<v-icon icon='bag--outline-without' size='16' class='a-offer-extras-baggage-item-icon' />
					<span class='a-offer-extras-baggage-item-label'>{{$t("account.offers.baggage.no_checked_baggage")}}</span>
				</div>
			</div>
			<div class="a-offer-extras-baggage-item a-radio" v-for='(item, i) in items' :key='i'
				@click='$emit("add-baggage", item.id)'
				:class='{"active": baggage.find(el => el.baggageId === item.id && el.passengerId === passenger.id)}'
			>
				<div class="a-offer-extras-baggage-item-content">
					<span class='a-radio-icon'></span>
					<v-icon icon='baggage' size='16' class='a-offer-extras-baggage-item-icon' />
					<span class='a-offer-extras-baggage-item-label'>{{item.name}}</span>
				</div>
				<div class="a-offer-extras-baggage-item-action">
					<p class='a-offer-extras-baggage-item-total'>{{$helpers.amount(item.amount, item.currency)}}</p>
					<!-- <p class='a-offer-extras-baggage-item-label green--text'>{{$t("account.offers.baggage.included")}}</p> -->
				</div>
			</div>
		</template>

		<template v-else>
			<template v-if='itinerary.BaggageIncluded'>
				<div class="a-offer-extras-baggage-item a-radio active">
					<div class="a-offer-extras-baggage-item-content">
						<span class='a-radio-icon'></span>
						<v-icon icon='baggage' size='16' class='a-offer-extras-baggage-item-icon' />
						<span class='a-offer-extras-baggage-item-label'>{{$t("account.offers.baggage.checked_baggage")}} {{itinerary.Baggage.details.baggageWeight}}{{$t("account.offers.baggage.kg")}}</span>
					</div>
					<div class="a-offer-extras-baggage-item-action">
						<p class='a-offer-extras-baggage-item-label green--text'>{{$t("account.offers.baggage.included")}}</p>
					</div>
				</div>
			</template>
			<div>
				<v-alert
					icon='bag--outline-without'
					custom-background='rgba(7,82,68,.03)'
					small
				>
					<p class='v-alert-title'>{{$t("account.offers.baggage.no_additional_luggage_options")}}</p>
					<p class='v-alert-text' style='color: rgba(0,0,0,.5)'>{{$t("account.offers.baggage.add_a_request_for_checked_baggage")}}</p>
				</v-alert>

				<div style='margin-bottom: 40px; text-align: center;'>
					<span class='app-link green--text' @click='$emit("open-special-request")'>{{$t("account.offers.baggage.request_for_additional_luggage")}}</span>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		props: ['items', 'passenger', 'baggage', 'itinerary']
	}
</script>
