<template>
	<div class='a-offer-extras'>
		<div class='app-container'>
			<div class='a-offer-extras-wrap'>
				<div class='a-offer-extras-content'>
					<a-offer-choose-place
						:seat='seat'
						:segments='segments'
						@open-place='airplane = true'
					/>
					<a-offer-airplane
						v-model='airplane'
						:details='details'
						:initSeat='seat'
						@update-seat='seat = $event'
						@update-segments='segments = $event'
					/>
					<a-offer-baggage
						:details='details'
						@update-baggage='baggage = $event'
						@open-special-request='scrollFix("#special-request")'
					/>
					<a-offer-loyalty
						:details='details'
						@update-loyaltyProgram='loyaltyProgram = $event'
					/>
					<a-offer-special-request
						@input='($event) => userComment = $event'
						:openRequest='openRequest'
						@open-request-status='openRequest = false'
					/>
				</div>
				<a-offer-info
					@open-details='openDetails()'
					:details='details'
					:seat='seat'
					:baggage='baggage'
					:loyaltyProgram='loyaltyProgram'
					:userComment='userComment'
				/>
			</div>
		</div>

		<trips-card-details
			v-model='card_details'
			:card='details'
			@open-offer='card_details = false'
		/>
	</div>
</template>

<script>
	import AOfferChoosePlace from '@/views/account/offers/flights/offer/choose-place';
	import AOfferAirplane from '@/views/account/offers/flights/offer/airplane';
	import AOfferBaggage from '@/views/account/offers/flights/offer/baggage';
	import AOfferLoyalty from '@/views/account/offers/flights/offer/loyalty';
	import AOfferSpecialRequest from '@/views/account/offers/components/special-request';
	import AOfferInfo from '@/views/account/offers/flights/offer/info';
	import TripsCardDetails from '@/views/account/details/flights';

	export default {
		data: () => ({
			airplane: false,
			card_details: false,
			userComment: '',
			openRequest: false,
			segments: [],
			seat: [],
			baggage: [],
			loyaltyProgram: []
		}),
		props: ["details"],
		components: {
			AOfferChoosePlace,
			AOfferAirplane,
			AOfferBaggage,
			AOfferLoyalty,
			AOfferSpecialRequest,
			AOfferInfo,
			TripsCardDetails
		},
		methods: {
			openDetails() {
				this.card_details = true;
			},
			scrollFix(hashbang) {
				this.openRequest = true;

				setTimeout(() => {
					const container = document.querySelector(hashbang).offsetTop;
					document.querySelector('.a-offer-scroll').scrollTo({
						top: container,
						behavior: 'smooth'
					})
				}, 1)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-extras {
		padding: 40px 0 60px;
		@include minw( $grid-breakpoints-xs ) {
			padding: 60px * .8 0;
		}
		@include minw( $grid-breakpoints-md ) {
			padding: 60px 0;
		}
		&-wrap {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			@include minw( $grid-breakpoints-sm ) {
				flex-direction: row;
			}
		}
		&-content {
			width: 100%;
			flex: 1 1 100%
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 10px;
			@include minw( $grid-breakpoints-md ) {
				font-size: 22px * .9;
				margin-bottom: 15px;
			}
			@include minw( $grid-breakpoints-lg ) {
				font-size: 22px;
			}
		}
		&-box {
			margin-bottom: 55px * .8;
			@include minw( $grid-breakpoints-lg ) {
				margin-bottom: 55px;
			}
		}
		&-card {
			border: 1px solid rgba($black, .1);
			border-radius: 5px;
			padding: 15px 20px;
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-md ) {
				padding: 30px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 30px;
			}
		}
		&-user {
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-lg ) {
				margin-bottom: 30px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				margin-bottom: 30px;
			}
		}
	}
</style>
