<template>
	<div class="a-offer-header">
		<div class='app-container app-container-fluid a-offer-header__container'>
			<div class='row'>
				<div class='col'>
					<p class='a-offer-header-title'>{{$t("account.offers.header.step")}} {{step}}/{{isBrandedFares ? 3 : 2}}</p>
				</div>
				<div class='col-auto'>
					<span class='a-offer-header-button-close' @click='closeOffer()'>
						<span class='mobile-hide'>{{$t("account.offers.header.back_to_ticket_selection")}}</span>
						<v-icon icon='cross' size='12'></v-icon>
					</span>
				</div>
			</div>
			<ul class='a-breadcrumbs-list a-offer-header-breadcrumbs'>
				<li class='a-breadcrumbs-item'>
					<span class='a-breadcrumbs-link link' @click='closeOffer()'>{{$t("account.offers.header.ticket_selection")}}</span>
					<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
				</li>
				<li class='a-breadcrumbs-item' :class='{active: step === 2}' v-if='isBrandedFares'>
					<span class='a-breadcrumbs-link link' @click='$emit("choose-step", 2)'>{{$t("account.offers.header.tariff_selection")}}</span>
					<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
				</li>
				<li class='a-breadcrumbs-item' :class='{active: isBrandedFares ? step === 3 : step === 2}'>
					<span class='a-breadcrumbs-link'>{{$t("account.offers.header.luggage_and_space")}}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["details", "step", "isBrandedFares"],
		methods: {
			closeOffer() {
				this.$router.push({
					name: "flights-search",
					params: {lang: this.$route.params.lang},
					query: {source: this.$route.query.source}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		background-color: $white;
		&__container {
			padding-top: 15px;
			padding-bottom: 15px;
			@include minw( $grid-breakpoints-xs ) {
				padding-top: 26px;
				padding-bottom: 26px;
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 400;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 5px;
			}
		}
		&-button-close {
			font-size: 15px;
			font-weight: 400;
			color: rgba($black, .6);
			cursor: pointer;
			display: flex;
			align-items: center;
			transition: color $transition;
			&:hover {
				color: $green-persian;
			}
			.app-icon {
				@include minw( $grid-breakpoints-xs ) {
					margin-left: 10px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					width: 17px !important;
					height: 17px !important;
				}
			}
		}
		&-breadcrumbs {
			.a-breadcrumbs {
				&-item {
					color: rgba($black, .4);
					&.active {
						.a-breadcrumbs-link {
							color: rgba($black, .8);
							@include minw( $grid-breakpoints-xs ) {
								font-weight: 700;
							}
						}
					}
				}
				&-link {
					border-color: transparent !important;
				}
			}
		}
	}
</style>
