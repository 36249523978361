<template>
	<div class='a-offer-extras-box'>
		<p class='a-offer-extras-title'>{{$t("account.offers.baggage.checked_baggage")}}</p>
		<div class='a-offer-extras-card a-offer-extras-baggage' v-for='(passenger, i) in details.passengers' :key='i'>
			<div class='a-offer-extras-user'>
				<a-user
					:user='passenger'
					:subTitle="`${passenger.type === 'children' ? (passenger.age === 0 ? $t('account.declOfNum.up_to_1_year') : `${passenger.age} ${$helpers.declOfNum(passenger.age, $t('account.declOfNum.years'))}`) : ''}`"
					:index='i'
				/>
			</div>
			<div class="row" v-for='(itinerary, i) in details.Itineraries' :key='i'>
				<div class="col-auto">
					<div class='a-card-details-info-header'>
						<div class='a-card-details-info-header-logo'>
							<img class='a-card-details-info-header-logo-img' :src="`https://api.bravo-travel.online/api${itinerary.Segments[0].AirlineLogo}`" alt="">
						</div>
						<div class='a-card-details-info-header-content'>
							<p class='a-card-details-info-header-title'>{{itinerary.OriginLoc}}</p>
							<v-icon icon='air' size='14' class='a-card-details-info-header-icon' />
							<p class='a-card-details-info-header-title'>{{itinerary.DestinationLoc}}</p>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="a-offer-extras-baggage-checkbox">
						<!-- <template v-if='i === 0'> -->
							<a-baggage-list
								:items='filterBaggage(itinerary.OriginLoc)'
								:passenger='passenger'
								:baggage='baggage'
								:itinerary='itinerary'
								@add-baggage='addBaggage($event, passenger)'
								@remove-baggage='removeBaggage($event)'
								@open-special-request='$emit("open-special-request")'
							/>
						<!-- </template>
						<div class="a-offer-extras-baggage-list" v-else>
							<p class='a-offer-extras-baggage-descr'>{{$t("account.offers.baggage.just_like_in_the_shipment")}}</p>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ABaggageList from '@/views/account/offers/flights/offer/baggage-list';

	export default {
		data: () => ({
			baggage: []
		}),
		props: ["details"],
		components: {
			ABaggageList
		},
		methods: {
			filterBaggage(OriginLoc) {
				return this.details.AdditionalServices.baggage.filter(el => el.originAirport == OriginLoc);
			},
			addBaggage($event, {id}) {
				this.baggage = [...this.baggage, {passengerId: id, baggageId: $event}]
				this.$emit("update-baggage", this.baggage);
			},
			removeBaggage({passengerId, baggageId}) {
				this.baggage = this.baggage.filter(el => !(el.passengerId === passengerId && el.baggageId === baggageId))
				this.$emit("update-baggage", this.baggage);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-extras {
		&-baggage {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.col,
				.col-auto {
					flex: 1 1 100%;
				}
			}
			&-list {
				width: 100%;
				margin-bottom: 30px;
				@include minw( $grid-breakpoints-xs ) {
					margin-left: auto;
					margin-bottom: 25px * .8;
					max-width: 360px;
				}
				@include minw( $grid-breakpoints-lg ) {
					margin-bottom: 25px;
				}
			}
			&-item {
				width: 100%;
				padding: 20px 15px;
				border: 1px solid rgba($black, .15);
				border-radius: $border-radius;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: border-color $transition;
				&:hover,
				&.active {
					border-color: $green-persian;
				}
				&:not(:last-child) {
					margin-bottom: 10px * .8;
					@include minw( $grid-breakpoints-lg ) {
						margin-bottom: 10px;
					}
				}
				&-content {
					display: flex;
					align-items: center;
					flex: 1 1 100%;
				}
				&-icon {
					flex-shrink: 0;
					margin-right: 10px;
					color: rgba($black, .5);
				}
				&-label {
					font-size: 12px;
					font-weight: 400;
					@include minw( $grid-breakpoints-md ) {
						font-size: 13px;
					}
				}
				&-action {
					flex-shrink: 0;
					margin-left: 10px;
				}
				&-total {
					font-size: 16px;
					font-weight: 700;
				}
			}
			&-descr {
				font-size: 13px;
				font-weight: 400;
				color: rgba($black, .5);
			}
		}
	}
</style>
