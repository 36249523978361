<template>
	<div class='flex a-card-clock-flex'>
		<div class='flex'>
			<div class='a-card-logo'
				v-tooltip.bottom='itinerary.Segments[0].AirlineName'
			>
				<img :src="`https://api.bravo-travel.online/api${itinerary.Segments[0].AirlineLogo}`" />
			</div>
			<div>
				<p class='a-card-title'>{{$moment(time.DepartureDT).format("HH:mm")}}</p>
				<p class='a-card-sub-title' v-tooltip.top="time.OriginLocName" style='cursor: help'>{{time.OriginLoc}}</p>
			</div>
			<div class='a-card-dots'>
				<span class='a-card-dot'></span>
				<span class='a-card-dot' v-for="(dot, i) in time.Stops" :key='i'
					v-tooltip.top='itineraryTitle(i)'
				></span>
				<span class='a-card-dot'></span>
			</div>
			<div>
				<p class='a-card-title last'>{{$moment(time.ArrivalDT).format("HH:mm")}}
					<span class='a-card-title-sup' v-if='$helpers.diffDays(time.DepartureDT, time.ArrivalDT) > 0'
						v-tooltip.top='`${$t("account.details.flights.arrival_in")} ${$moment(time.ArrivalDT).format("ddd, MMM DD")}`' style='cursor: help'
					>+{{$helpers.diffDays(time.DepartureDT, time.ArrivalDT)}}</span>
				</p>
				<p class='a-card-sub-title' v-tooltip.top="time.DestinationLocName" style='cursor: help'>{{time.DestinationLoc}}</p>
			</div>
		</div>
		<div class='a-card-time' v-if='!hideTotalTime'>
			<p class='a-card-text'>{{$helpers.timeName(time.ElapsedTime)}}</p>
			<template v-if='!segment'>
				<p class='a-card-text' style='color: #00AF85' v-if='time.Stops === 0'>{{$t('account.search.flights.direct_flight')}}</p>
				<p class='a-card-text'
					v-else
					v-tooltip.top="itinerary.Segments.map((item, index) => itinerary.Segments[index + 1] ? `${$helpers.timeName($moment(itinerary.Segments[index + 1].DepartureDT).diff($moment(itinerary.Segments[index].ArrivalDT), 'minutes'))} ${$t('account.details.flights.stop_at')} ${itinerary.Segments[index].DestinationLoc} <br>` : null).join(' ')"
					style='cursor: help'
				>
					{{time.Stops}} {{$helpers.declOfNum(time.Stops, $t('account.declOfNum.stops'))}}
				</p>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["itinerary", "segment", "hideTotalTime"],
		computed: {
			time() {
				return this.segment ? this.segment : this.itinerary
			}
		},
		methods: {
			itineraryTitle(index) {
				if(!this.itinerary.Segments[index + 1]) return;
				return `${this.$helpers.timeName(this.$moment(this.itinerary.Segments[index + 1].DepartureDT).diff(this.$moment(this.itinerary.Segments[index].ArrivalDT), 'minutes'))} ${this.$t('account.details.flights.stop_at')} ${this.itinerary.Segments[index].DestinationLoc}`
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card {
		&-logo {
			flex-shrink: 0;
			width: 40px;
			height: 36px;
			margin-right: 10px;
			@include minw($grid-breakpoints-xs) {
				width: 50px * .7;
				height: 45px * .7;
				margin-right: 29px * .4;
			}
			@include minw($grid-breakpoints-md) {
				width: 50px * .8;
				height: 45px * .8;
				margin-right: 29px * .6;
			}
			@include minw($grid-breakpoints-lg) {
				width: 50px;
				height: 45px;
				margin-right: 29px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		&-dots {
			width: 42px;
			margin: 8px 10px;
			display: flex;
			justify-content: space-between;
			@include minw($grid-breakpoints-xs) {
				width: 59px * .7;
				margin: 8px * .6 14px * .7;
			}
			@include minw($grid-breakpoints-md) {
				width: 59px * .8;
				margin: 8px * .7 14px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				width: 59px;
				margin: 8px 14px;
			}
			&:before {
				content: '';
				position: absolute;
				top: 2px;
				left: 0;
				right: 0;
				height: 2px;
				background-color: #dae5e3;
			}
		}
		&-dot {
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #B5CBC7;
			&:first-child,
			&:last-child {
				background-color: #dae5e3;
			}
		}
		&-time {
			flex-shrink: 0;
			@include minw($grid-breakpoints-xxs) {
				margin-left: 12px;
			}
			@include minw($grid-breakpoints-xs) {
				margin-left: 45px;
			}
			@include minw($grid-breakpoints-sm) {
				margin-left: 45px * .1;
			}
			@include minw($grid-breakpoints-md) {
				margin-left: 45px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin-left: 3.25vw;
			}
			@include maxw($grid-breakpoints-xxs - 1) {
				margin-left: 50px;
				display: flex;
				.a-card-text {
					margin-top: 5px;
					margin-right: 5px;
				}
			}
		}
	}
</style>
