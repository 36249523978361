<template>
	<div class='a-offer-extras-box'>
		<p class='a-offer-extras-title'>{{$t("account.itinerary.timeline.flights.loyalty_program")}}</p>
		<div class='a-offer-extras-card a-offer-extras-baggage' v-for='(passenger, i) in details.passengers' :key='i'>
			<div class='a-offer-extras-user'>
				<a-user
					:user='passenger'
					:subTitle="`${passenger.type === 'children' ? (passenger.age === 0 ? $t('account.declOfNum.up_to_1_year') : `${passenger.age} ${$helpers.declOfNum(passenger.age, $t('account.declOfNum.years'))}`) : ''}`"
					:index='i'
				/>
			</div>
			<div class="row">
				<div class="col-auto">
					<div class='a-card-details-info-header'>
						<div class='a-card-details-info-header-logo'>
							<img class='a-card-details-info-header-logo-img' :src="`https://api.bravo-travel.online/api${details.ValidCarrierLogo}`" alt="">
						</div>
						<div class='a-card-details-info-header-content'>
							<p class='a-card-details-info-header-title'>{{details.ValidCarrierName}}</p>
						</div>
					</div>
				</div>
				<AOfferLoyaltyAddProgram
					:passenger='passenger'
					:loyaltyProgram='loyaltyProgram'
					@update-loyaltyProgram='addLoyaltyProgram($event, passenger)'
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import AOfferLoyaltyAddProgram from '@/views/account/offers/flights/offer/loyalty_addProgram';

	export default {
		data: () => ({
			loyaltyProgram: []
		}),
		props: ['details'],
		components: {
			AOfferLoyaltyAddProgram
		},
		methods: {
			addLoyaltyProgram($event, {id}) {
				this.loyaltyProgram = this.loyaltyProgram.filter(el => el.passengerId != id);
				this.loyaltyProgram = [...this.loyaltyProgram, {passengerId: id, ...$event}];
				this.$emit("update-loyaltyProgram", this.loyaltyProgram);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-extras {
		&-loyalty {
			&-add {
				display: flex;
				align-items: center;
				cursor: pointer;
				min-height: 34px;
				&-icon {
					flex-shrink: 0;
					margin-right: 10px;
					@include minw( $grid-breakpoints-md ) {
						margin-right: 15px;
					}
				}
				&-title {
					font-size: 14px;
					font-weight: 400;
					@include minw( $grid-breakpoints-md ) {
						font-size: 15px;
					}
				}
			}
			&-info {
				font-size: 13px;
				font-weight: 400;
				padding: 6px 12px;
				border: 1px solid transparent;
				border-radius: 10px;
				display: inline-flex;
				align-items: center;
				cursor: pointer;
				transition: border-color $transition;
				&:hover {
					border-color: rgba($black, .1)
				}
				strong {
					display: contents;
				}
				.app-icon {
					margin-left: 10px;
					flex-shrink: 0;
				}
				&-no {
					cursor: default;
					color: rgba($black, .6);
					background: rgba(7, 82, 68, 0.03);
					border-color: rgba($black, .1)
				}
			}
			&-form {
				&-row {
					display: flex;
					margin-left: -5px;
					margin-right: -5px;
					@include maxw( $grid-breakpoints-xs - 1 ) {
						flex-direction: column;
					}
				}
				&-cell {
					width: 100%;
					padding-left: 5px;
					padding-right: 5px;
					@include minw( $grid-breakpoints-xs ) {
						width: 205px;
					}
					.a-select {
						margin-bottom: 10px;
					}
					.app-input {
						.app-input__slot {
							min-height: 40px;
						}
						.app-input__field input {
							padding: 6px 0;
							font-size: 13px;
						}
					}
				}
				&-footer {
					display: flex;
					justify-content: flex-end;
					.app-btn {
						font-size: 12px;
						height: 40px;
						padding: 0px 9px;
						min-width: auto;
						@include minw( $grid-breakpoints-xs ) {
							margin-left: 5px;
							height: 27px;
						}
						@include maxw( $grid-breakpoints-xs - 1 ) {
							width: 100%;
							&.app-btn--outline {
								width: 46vw;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
</style>
