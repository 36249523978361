<template>
	<div class='a-offer-extras-info'>
		<div class='a-offer-extras-box'>
			<p class='a-offer-extras-title'>{{$t("account.offers.info.flight_result")}}</p>
			<div class='a-offer-extras-card'>
				<div @click='$emit("open-details")' style='cursor: pointer'>
					<div class='a-offer-extras-info-card' v-for='(itinerary, i) in details.Itineraries' :key='i'>
						<p class='a-offer-extras-info-card-title'>{{$moment(itinerary.DepartureDT).format("ddd, DD MMM")}}</p>
						<a-card-time
							:itinerary='itinerary'
						/>
					</div>

					<div class='a-offer-extras-info-button'>
						<span class='app-link green--text' @click='$emit("open-details")'>{{$t('account.details.flights.show_details')}}</span>
					</div>
				</div>

				<div class="divider a-offer-extras-info-divider"></div>

				<div>
					<p class='a-offer-extras-info-title'>{{$t("account.offers.info.air_tickets")}}</p>
					<div class="row">
						<div class="col">
							<p class='a-offer-extras-info-text'>{{details.passengers.length}} x {{$helpers.declOfNum(details.passengers.length, $t('account.declOfNum.tickets'))}} {{source.type === 'both' ? $t("account.offers.info.there_and_back") : $t("account.offers.info.there")}} {{details.Itineraries[0].OriginLoc}} - {{details.Itineraries[0].DestinationLoc}}</p>
							<p class='a-offer-extras-info-text a-offer-extras-info-descr' v-if='detail.TotalFare && details.passengers.length > 1'>{{$helpers.amount((detail.TotalFare.Amount / details.passengers.length), detail.TotalFare.CurrencyCode)}} {{$t("account.offers.info.from_the_passenger")}}</p>
						</div>
						<div class="col-auto">
							<p class='a-offer-extras-info-text' v-if='detail.TotalFare'>{{$helpers.amount(detail.TotalFare.Amount, detail.TotalFare.CurrencyCode)}}</p>
						</div>
					</div>
				</div>

				<div v-if='baggage.length || $_.some(details.Itineraries, "BaggageIncluded") || seat.length'>
					<p class='a-offer-extras-info-title'>{{$t("account.offers.header.luggage_and_space")}}</p>
					<div class='a-offer-extras-info-mb' v-if='baggage.length || $_.some(details.Itineraries, "BaggageIncluded")'>
						<template v-if='baggage.length'>
							<div class="row" v-for='(item, i) in $_.groupBy(this.baggage, "baggageId")' :key='i'>
								<div class="col">
									<p class='a-offer-extras-info-text'>{{item.length}} x {{findBaggage(i).name}}
										<!-- {{findBaggage(i).originAirport}} - {{findBaggage(i).destinationAirport}} -->
									</p>
								</div>
								<div class="col-auto">
									<p class='a-offer-extras-info-text'>{{findBaggage(i) ? $helpers.amount(findBaggage(i).amount * item.length, findBaggage(i).currency) : null}}</p>
								</div>
							</div>
						</template>
						<template v-if='$_.some(details.Itineraries, "BaggageIncluded")'>
							<div class="row" v-for='(itinerary, i) in details.Itineraries' :key='i'>
								<div class="col">
									<p class='a-offer-extras-info-text'>{{details.passengers.length}} x {{$helpers.declOfNum(details.passengers.length, $t('account.declOfNum.tickets'))}} {{source.type === 'both' ? $t("account.offers.info.there_and_back") : $t("account.offers.info.there")}} {{itinerary.OriginLoc}} - {{itinerary.DestinationLoc}}</p>
								</div>
								<div class="col-auto">
									<p class='a-offer-extras-info-text green--text'>{{$t("account.offers.info.free")}}</p>
								</div>
							</div>
						</template>
					</div>
					<div class="row" v-if='seat.length'>
						<div class="col">
							<p class='a-offer-extras-info-text'>{{seat.length}} x {{$t("account.offers.choose_place.choose_place")}}</p>
							<!-- <p class='a-offer-extras-info-text a-offer-extras-info-descr'>{{$t("account.offers.info.includes_space_maintenance_fee")}} 205,16 грн</p> -->
						</div>
						<div class="col-auto">
							<p class='a-offer-extras-info-text'>{{$helpers.amount($_.sumBy(seat, (by) => Number(by.price.amount)), seat[0].price.currency)}}</p>
						</div>
					</div>
				</div>

				<div>
					<p class='a-offer-extras-info-title'>{{$t('account.special_request.title')}}</p>
					<p class='a-offer-extras-info-text a-offer-extras-info-mb'>{{$t("account.offers.info.descr_1")}}</p>
					<p class='a-offer-extras-info-text a-offer-extras-info-descr'>{{$t("account.offers.info.descr_2")}}</p>
				</div>

				<div class="divider a-offer-extras-info-total-divider"></div>
				<div class="row a-offer-extras-info-total">
					<div class="col">
						<p class='a-offer-extras-info-total-text' style='margin-bottom: 0'>{{$t("account.offers.info.total_price")}}</p>
					</div>
					<div class="col-auto">
						<p class='a-offer-extras-info-total-text' v-if='detail.TotalFare'>{{totalFare()}}</p>
					</div>
				</div>
				<v-button block small color="green white--text" @click='addOrder' :loading='loading_btn'>{{$t("account.offers.info.add_a_flight_to_the_trip")}}</v-button>
			</div>
		</div>
	</div>
</template>

<script>
	import ACardTime from '@/views/account/search/flights/card/time';
	import { addOrder } from '@/services/request';

	export default {
		data: () => ({
			detail: {},
			loading_btn: false
		}),
		props: ["details", "seat", "baggage", "loyaltyProgram", "userComment"],
		components: {
			ACardTime
		},
		computed: {
			source() {
				return JSON.parse(this.$route.query.source);
			}
		},
		created() {
			let BrandedFare = this.details.BrandedFares.find(el => el.trip.code === this.$route.query.offerId);
			this.detail = BrandedFare ? BrandedFare.trip : this.details
		},
		methods: {
			addOrder() {
				this.loading_btn = true;
				let replaceOfferId = this.source.replaceOfferId ? { replaceOfferId: this.source.replaceOfferId } : false;

				addOrder({
					details: {
						seat: this.seat,
						baggage: this.baggage,
						loyaltyProgram: this.loyaltyProgram,
						fareOfferId: this.$route.params.code !== this.$route.query.offerId ? this.$route.query.offerId : null,
						userComment: this.userComment
					},
					type: "flight",
					orderId: this.$route.params.orderId,
					offerId: this.$route.params.code,
					storageId: this.$route.params.storageId,
					...replaceOfferId
				}).then(res => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: res.data.id, lang: this.$route.params.lang},
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			findBaggage(i) {
				return this.details.AdditionalServices.baggage.find(el => el.id === i);
			},
			totalFare() {
				let amountBaggage = this.baggage.reduce((total, amount) => Number(total) + Number(this.details.AdditionalServices.baggage.find(item => item.id === amount.baggageId).amount), 0);
				let amountSeat = this.seat.length ? this.$_.sumBy(this.seat, (by) => Number(by.price.amount)) : 0;
				return this.$helpers.amount(this.detail.TotalFare.Amount + amountBaggage + amountSeat, this.detail.TotalFare.CurrencyCode)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-extras {
		&-info {
			position: sticky;
			position: -webkit-sticky;
			top: 0px;
			flex-shrink: 0;
			width: 100%;
			@include minw( $grid-breakpoints-sm ) {
				width: 540px * .7;
				margin-left: 80px * .6;
			}
			@include minw( $grid-breakpoints-lg ) {
				width: 540px * .8;
				margin-left: 80px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				width: 540px;
				margin-left: 80px;
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
				margin-top: 20px * .8;
				margin-bottom: 2px;
				@include minw( $grid-breakpoints-lg ) {
					margin-top: 20px;
				}
			}
			&-text {
				font-size: 12px;
				line-height: 1.5;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
				}
			}
			&-descr {
				color: rgba($black, .4);
			}
			&-card {
				&:not(:last-child) {
					margin-bottom: 38px * .8;
					@include minw( $grid-breakpoints-lg ) {
						margin-bottom: 38px;
					}
				}
				&-title {
					font-size: 14px;
					font-weight: 400;
					color: rgba($black, .5);
					@include minw( $grid-breakpoints-xs ) {
						font-size: 16px;
						font-weight: 700;
						margin-bottom: 7px;
					}
				}
				.a-card-clock-item {
					margin-right: 0;
				}
				.a-card-time {
					margin-left: auto;
					padding-left: 8px;
				}
			}
			&-button {
				text-align: center;
			}
			&-divider {
				margin-top: 30px * .8;
				@include minw( $grid-breakpoints-lg ) {
					margin-top: 30px;
				}
			}
			&-mb {
				margin-bottom: 15px * .8;
				@include minw( $grid-breakpoints-lg ) {
					margin-bottom: 15px;
				}
			}
			&-total {
				margin-bottom: 30px * .8;
				@include minw( $grid-breakpoints-lg ) {
					margin-bottom: 30px;
				}
				&-divider {
					margin-top: 10px * .8;
					margin-bottom: 20px * .8;
					@include minw( $grid-breakpoints-lg ) {
						margin-top: 10px;
						margin-bottom: 20px;
					}
				}
				&-text {
					font-size: 16px;
					font-weight: 700;
					@include minw( $grid-breakpoints-xs ) {
						font-size: 18px;
					}
				}
			}
		}
	}

</style>
