<template>
	<div class='a-offer'>
		<a-offer-header
			:details='details'
			:step='step'
			:isBrandedFares='isBrandedFares'
			@choose-step='step = $event'
		/>
		<div class="a-offer-scroll">
			<transition name="fade" mode="out-in">
				<a-offer-preloader :preloader='preloader' v-if='isPreloader' />
				<a-offer-preloader :preloader='preloader' :errors='errors' v-else-if='!isPreloader && errors' />
				<a-tariff v-else-if='isBrandedFares && step === 2'
					:details='details'
					@choose-step='$event => step = $event'
				/>
				<a-offer v-else
					:details='details'
				/>
			</transition>
		</div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import AOfferHeader from '@/views/account/offers/components/header';
	import AOfferPreloader from '@/views/account/offers/components/preloader';
	import ATariff from '@/views/account/offers/flights/tariff';
	import AOffer from '@/views/account/offers/flights/offer';
	import { flightDetails } from '@/services/request';

	export default {
		data: () => ({
			preloader: true,
			isPreloader: true,
			details: null,
			step: 1,
			isBrandedFares: false,
			errors: null
		}),
		components: {
			AOfferHeader,
			AOfferPreloader,
			ATariff,
			AOffer
		},
		created() {
			this.flightDetails();
			setTimeout(() => {
				disablePageScroll(document.querySelector('.a-offer-scroll'));
			}, 150);
		},
		methods: {
			flightDetails() {
				this.preloader = true;
				this.isPreloader = true;
				this.errors = null;

				flightDetails({storageId: this.$route.params.storageId, code: this.$route.params.code}).then(res => {
					this.details = res.data;
					this.isBrandedFares = this.details.BrandedFares.length;
					this.preloader = false;

					if(this.isBrandedFares === 0 && !this.$route.query.offerId) {
						this.$router.replace({
							query: {offerId: this.details.code, source: this.$route.query.source}
						})
					}

					setTimeout(() => {
						this.step = this.isBrandedFares && this.$route.query.offerId ? 3 : 2;
						this.isPreloader = false;
					}, 1000)
				}).catch(err => {
					this.preloader = false;
					this.isPreloader = false;
					this.errors = err.response.data?.errors ?? null;
				})
			}
		},
		beforeDestroy() {
			this.details = null;
			this.errors = null;
			setTimeout(() => {
				enablePageScroll(document.querySelector('.a-offer-scroll'));
			}, 150);
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		background-color: $white;
		&-scroll {
			padding-top: 78px;
			width: 100%;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			@include minw( $grid-breakpoints-xs ) {
				padding-top: 100px;
			}
		}
	}
</style>
