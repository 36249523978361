<template>
	<div class='a-offer-airplane-info'>
		<div class='a-offer-airplane-info-list'>
			<div class='a-offer-airplane-info-item' v-for='(item, i) in items' :key='i'>
				<div class="a-offer-airplane-info-item-icon">
					<span class='a-offer-airplane-info-item-icon-dot' :style='`background-color: ${item.color}`' v-if='item.color'></span>
					<span class='a-offer-airplane-info-item-icon-dot' v-else>
						<v-icon icon='cross' size='14' />
					</span>
				</div>
				<div class="a-offer-airplane-info-item-content">
					<p class='a-offer-airplane-info-item-title'>{{item.title}}</p>
					<p class='a-offer-airplane-info-item-text' v-if='item.text'>{{item.text}}</p>
					<p class='a-offer-airplane-info-item-total' v-if='item.total'>{{item.total}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { i18n } from '@/plugins/i18n'

	export default {
		props: ['seatMap'],
		computed: {
			items() {
				let compactSeat = this.$_.flattenDeep(this.seatMap.row.map(el => el.seat));

				let total = (items) => {
					let minBy = this.$_.minBy(items, (by) => Number(by.price.amount));
					let maxBy = this.$_.maxBy(items, (by) => Number(by.price.amount));

					return `${this.$helpers.amount(minBy.price.amount, minBy.price.currency)}${minBy.price.amount !== maxBy.price.amount ? ` - ${this.$helpers.amount(maxBy.price.amount, maxBy.price.currency)}` : ''}`;
				}

				let isComfort = compactSeat.filter(el => el.isComfort && el.isAvailable).length ? [{
					color: '#D7617D',
					title: i18n.t("account.offers.flights.extra_legroom"),
					text: i18n.t("account.offers.flights.more_comfortable_with_extra_legroom"),
					total: total(compactSeat.filter(el => el.isComfort && el.isAvailable))
				}] : [];
				let isPreferred = compactSeat.filter(el => el.isPreferred && el.isAvailable).length ? [{
					color: '#F7BD4C',
					title: i18n.t("account.offers.flights.the_best_place"),
					text: i18n.t("account.offers.flights.choose_the_most_popular_place"),
					total: total(compactSeat.filter(el => el.isPreferred && el.isAvailable))
				}] : [];
				let isAvailable = compactSeat.filter(el => el.isAvailable && !el.isComfort && !el.isPreferred && !el.isExitRowSeat).length ? [{
					color: '#00B796',
					title: i18n.t("account.offers.flights.standard_place"),
					text: i18n.t("account.offers.flights.choose_your_favorite_place"),
					total: total(compactSeat.filter(el => el.isAvailable && !el.isComfort && !el.isPreferred && !el.isExitRowSeat))
				}] : [];
				let isExitRowSeat = compactSeat.filter(el => el.isExitRowSeat && el.isAvailable).length ? [{
					color: '#BB6BD9',
					title: i18n.t("account.offers.flights.place_at_the_exit"),
					total: total(compactSeat.filter(el => el.isExitRowSeat && el.isAvailable))
				}] : [];
				let notAvailable = compactSeat.filter(el => !el.isAvailable).length ? [{title: i18n.t("account.offers.flights.not_available")}] : [];

				return [
					...isComfort,
					...isPreferred,
					...isAvailable,
					...isExitRowSeat,
					{color: '#075244', title: i18n.t("account.offers.flights.selected_place")},
					...notAvailable
				]
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-airplane-info {
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		flex-shrink: 0;
		@include minw($grid-breakpoints-md) {
			padding: 60px * .8 45px * .8;
		}
		@include minw($grid-breakpoints-lg) {
			padding: 60px 45px;
		}
		&-list {
			@include minw($grid-breakpoints-md) {
				background-color: $white;
				box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
				border-radius: 10px;
				padding: 40px * .8 30px * .8;
				width: 380px * .9;
			}
			@include minw($grid-breakpoints-lg) {
				padding: 40px 30px;
				width: 380px;
			}
		}
		&-item {
			display: flex;
			align-items: flex-start;
			&:not(:last-child) {
				margin-bottom: 30px * .8;
				@include minw($grid-breakpoints-lg) {
					margin-bottom: 30px;
				}
			}
			&-icon {
				display: grid;
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				margin-right: 15px;
				@include minw($grid-breakpoints-xs) {
					width: 30px * .8;
					height: 30px * .8;
					margin-right: 20px * .8;
				}
				@include minw($grid-breakpoints-lg) {
					width: 30px;
					height: 30px;
					margin-right: 20px;
				}
				&-dot {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					color: rgba($cyprus, .3);
					background-color: rgba($cyprus, .05);
					.app-icon {
						@include maxw($grid-breakpoints-md - 1) {
							width: 10px !important;
							height: 10px !important;
						}
					}
				}
			}
			&-title {
				font-size: 15px;
				font-weight: 400;
				line-height: 1.5;
				@include minw($grid-breakpoints-xs) {
					font-size: 18px * .9;
				}
				@include minw($grid-breakpoints-lg) {
					font-size: 18px;
				}
			}
			&-text {
				font-size: 12px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba($black, .5);
				margin-bottom: 5px;
				@include minw($grid-breakpoints-xs) {
					font-size: 14px * .9;
				}
				@include minw($grid-breakpoints-lg) {
					font-size: 14px;
				}
			}
			&-total {
				font-size: 13px;
				font-weight: 400;
				line-height: 1.5;
				@include minw($grid-breakpoints-xs) {
					font-size: 16px * .9;
				}
				@include minw($grid-breakpoints-lg) {
					font-size: 16px;
				}
			}
		}
	}
</style>
