<template>
	<div class="col-auto ml-auto">
		<template v-if='add_program'>
			<ValidationObserver ref="form" v-slot="{ invalid }">
				<div class='a-offer-extras-loyalty-form'>
					<div class='a-offer-extras-loyalty-form-row'>
						<div class='a-offer-extras-loyalty-form-cell'>
							<ValidationProvider rules="required">
								<a-select
									:placeholder='$t("account.form.placeholder.name_program")'
									:options='itemsLoyaltyPrograms'
									v-model='form.programId'
									xxsmall
									class='a-loyalty-program-select'
								/>
							</ValidationProvider>
						</div>
						<div class='a-offer-extras-loyalty-form-cell'>
							<ValidationProvider :name='$t("account.form.placeholder.number_program")' rules="required|numeric" v-slot='{errors}'>
								<v-text-field
									:placeholder='$t("account.form.placeholder.number_program")'
									name='number'
									v-model='form.number'
									:errorMessage='errors[0]'
								/>
							</ValidationProvider>
						</div>
					</div>
					<div class='a-offer-extras-loyalty-form-footer'>
						<v-button outline color="green" @click='add_program = false'>{{$t("account.buttons.cancel")}}</v-button>
						<v-button color="green white--text" @click='save_program()' :disabled='invalid'>{{$t("account.buttons.save")}}</v-button>
					</div>
				</div>
			</ValidationObserver>
		</template>

		<span class='a-offer-extras-loyalty-info' v-else-if='loyaltyProgramActive'
			@click='add_program = true'
		>
			<strong>{{loyaltyProgramActive.name}} </strong> {{loyaltyProgramActive.number}}
			<v-icon icon='edit' :size='12' />
		</span>

		<div class='a-offer-extras-loyalty-add green--text' v-else>
			<v-icon icon='plus' size='10' class='a-offer-extras-loyalty-add-icon' />
			<p class='a-offer-extras-loyalty-add-title' @click='add_program = true'>{{$t("account.offers.loyalty.add_program")}}</p>
		</div>

		<!-- <span class='a-offer-extras-loyalty-info a-offer-extras-loyalty-info-no'>{{$t("account.offers.loyalty.there_are_no_relevant_loyalty_programs")}}</span> -->
	</div>
</template>

<script>
	import { getAutocompleteLoyaltyPrograms } from '@/services/request';

	export default {
		data: () => ({
			add_program: false,
			form: {
				programId: null,
				number: ''
			},
			itemsLoyaltyPrograms: []
		}),
		props: ['passenger', 'loyaltyProgram'],
		computed: {
			loyaltyProgramActive() {
				return this.loyaltyProgram.find(el => el.passengerId === this.passenger.id)
			}
		},
		methods: {
			save_program() {
				this.$emit("update-loyaltyProgram", {...this.form, name: this.itemsLoyaltyPrograms.find(el => el.id === this.form.programId).name});
				this.add_program = false;
			}
		},
		created() {
			if(this.passenger?.loyaltyPrograms.length) {
				this.form.number = this.passenger.loyaltyPrograms[0].number;
				this.form.programId = this.passenger.loyaltyPrograms[0].programId;
				this.$emit("update-loyaltyProgram", {...this.form, name: this.passenger.loyaltyPrograms[0].name});
			}
		},
		watch: {
			add_program() {
				if(this.add_program) {
					getAutocompleteLoyaltyPrograms().then((res) => {
						this.itemsLoyaltyPrograms = res.data

						if(this.loyaltyProgramActive) {
							this.form.number = this.loyaltyProgramActive.number;
							this.form.programId = this.loyaltyProgramActive.programId;
						}
					});
				} else {
					this.form = {
						programId: null,
						number: ''
					},
					this.itemsLoyaltyPrograms = []
				}
			}
		}
	}
</script>
