<template>
	<div class='a-tariff'>
		<div class='app-container app-container-fluid a-container'>
			<p class='a-tariff-title'>{{$t("account.offers.tariff.want_to_fly_more_comfortably")}}</p>
			<p class='a-tariff-descr'>{{$t("account.offers.tariff.choose_the_tariff_that_suits_you")}}</p>

			<div class='a-tariff-content'>
				<carousel
					:perPage='1'
					:scrollPerPage='false'
					:paginationEnabled='false'
					class='a-tariff-carousel mobile-hide'
					:class='details.BrandedFares.length <= 3 ? "a-tariff-carousel-custom" : ""'
					:navigationEnabled='true'
					navigation-next-label=""
					navigation-prev-label=""
					:perPageCustom="[[600, 2], [960, 3], [1280, 4]]"
				>
					<slide v-for='(item, i) in details.BrandedFares' :key='i'>
						<a-tariff-card
							:item='item'
							:isActiveCard='isActiveCard(item)'
							@check-tariff='checkTariff($event)'
						>
							<div class='a-tariff-item-action'>
								<div @click='openModal = true, card = item'>
									<v-icon icon='info' size='20' class='a-tariff-item-action-icon' />
								</div>
							</div>
						</a-tariff-card>
					</slide>
				</carousel>

				<div>
					<div v-for='(item, i) in details.BrandedFares' :key='i' class='mobile-show'>
						<a-tariff-card
							:item='item'
							:isActiveCard='isActiveCard(item)'
							@check-tariff='checkTariff($event)'
						>
							<div class='a-tariff-item-action'>
								<div @click='openModal = true, card = item'>
									<v-icon icon='info' size='20' class='a-tariff-item-action-icon' />
								</div>
							</div>
						</a-tariff-card>
					</div>
				</div>

				<div class='a-tariff-banner'>
					<!-- <v-alert
						color='orange'
						text
					>
						<div>
							<p class='v-alert-text'>{{$t("account.alert.covid.descr_2")}}
								<a href="" class='v-alert-link'>{{$t("account.alert.learn_more")}}
									<v-icon icon='arrow-right' size='12' />
								</a>
							</p>
						</div>
					</v-alert> -->
				</div>
			</div>
		</div>

		<a-dialog-branded-fares
			v-model='openModal'
			@close-dialog='openModal = false, card = null'
			:card='card'
			:isActiveCard='card ? isActiveCard(card) : false'
			@check-tariff='checkTariff($event)'
		/>
	</div>
</template>

<script>
	import { Carousel, Slide } from 'vue-carousel';
	import ATariffCard from '@/views/account/offers/flights/tariff-card';
	import ADialogBrandedFares from '@/views/account/offers/flights/dialogBrandedFares';

	export default {
		data: () => ({
			openModal: false,
			card: null
		}),
		props: ["details"],
		components: {
			Carousel,
			Slide,
			ATariffCard,
			ADialogBrandedFares
		},
		methods: {
			isActiveCard(item) {
				return (this.$route.query.offerId ?? this.$route.params.code) === item.trip.code;
			},
			checkTariff(item) {
				this.$emit("choose-step", 3);
				if(this.$route.query.offerId !== item.trip.code) {
					this.$router.replace({
						query: {offerId: item.trip.code, source: this.$route.query.source}
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-tariff {
		padding: 40px 0 60px;
		text-align: center;
		@include minw( $grid-breakpoints-xs ) {
			padding: 60px * .8 0;
		}
		@include minw( $grid-breakpoints-md ) {
			padding: 60px 0;
		}
		&-title {
			font-size: 20px * .9;
			font-weight: 700;
			margin-bottom: 5px;
			@include minw( $grid-breakpoints-md ) {
				font-size: 20px;
			}
		}
		&-descr {
			font-size: 16px * .9;
			font-weight: 400;
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 10px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
		&-content {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				display: flex;
				flex-direction: column-reverse;
			}
		}
		&-carousel {
			@include minw( $grid-breakpoints-xs ) {
				margin: 60px * .8 0;
			}
			@include minw( $grid-breakpoints-md ) {
				margin: 60px 0;
			}
			&-custom {
				.VueCarousel-inner {
					justify-content: center;
				}
			}
		}
		&-banner {
			max-width: 820px;
			margin: 0 auto;
			text-align: left;
		}
	}
	.VueCarousel-navigation {
		position: initial;
		&-prev,
		&-next {
			background-color: $green !important;
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
		&-prev {
			mask-image: url("../../../../assets/svg/arrow-prev.svg");
		}
		&-next {
			mask-image: url("../../../../assets/svg/arrow-next.svg");
		}
		&--disabled {
			opacity: 0 !important;
		}
	}
</style>
