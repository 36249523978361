<template>
	<div class='a-offer-preloader'>
		<div>
			<div class='a-offer-preloader-tickets'>
				<span class='a-offer-preloader-ticket' :class='{"animation-first": preloader}' v-html="require(`!html-loader!@/assets/preloader/ticket.svg`)" />
				<span class='a-offer-preloader-ticket animation-second' v-html="require(`!html-loader!@/assets/preloader/ticket.svg`)" v-if='preloader' />
				<span class='a-offer-preloader-check error--text' v-else-if='errors'>
					<v-icon icon='cross' size='18' />
				</span>
				<span class='a-offer-preloader-check' v-else>
					<v-icon icon='check' size='22' />
				</span>
			</div>
			<template v-if='errors'>
				<p class='a-offer-preloader-title'>
					{{errors}}
				</p>
			</template>
			<template v-else>
				<p class='a-offer-preloader-title'>
					<v-icon icon='check' size='22' v-if='!preloader' />
					{{preloader ? $t("account.preloader.check_current_prices_and_availability") : $t("account.preloader.we_found_a_great_offer_for_this_flight")}}
				</p>
				<p class='a-offer-preloader-text' v-if='preloader'>{{$t("account.preloader.we_offer_you_the_best_offers_from_the_world_list")}}</p>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["preloader", "errors"]
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-preloader {
		min-height: 100%;
		display: grid;
		align-items: center;
		justify-content: center;
		text-align: center;
		&-tickets {
			width: 200px;
			height: 80px;
			margin: auto;
			margin-bottom: 80px;
			@include minw( $grid-breakpoints-xs ) {
				width: 250px;
				height: 100px;
			}
		}
		&-ticket {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			&.animation {
				&-first {
					animation: ticket-first 2s ease infinite;
				}
				&-second {
					z-index: -1;
					animation: ticket-second 2s ease infinite;
				}
			}
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 10px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 20px;
				margin-bottom: 6px;
			}
			.app-icon {
				display: inline-block;
				color: $green-persian;
				margin-right: 10px;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 25px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					width: 12px !important;
					height: 12px !important;
				}
			}
		}
		&-text {
			font-size: 14px;
			font-weight: 400;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				max-width: 274px;
				margin: auto;
			}
		}
		&-check {
			position: absolute;
			top: -10px;
			right: -15px;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			color: $green-persian;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #EEFAF8;
			border: 3px solid $green-persian;
			@include minw( $grid-breakpoints-xs ) {
				top: -15px;
				right: -20px;
				width: 46px;
				height: 46px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.app-icon {
					width: 14px !important;
					height: 14px !important;
				}
			}
			&.error--text {
				border-color: $red;
			}
		}
	}
	@keyframes ticket-first {
		0% { transform: rotate(0deg); }
		50% { transform: rotate(-5deg); }
		100% { transform: rotate(0deg); }
	}
	@keyframes ticket-second {
		0% { transform: rotate(0deg); }
		50% { transform: rotate(-15deg) translate(-20px, 40px); }
		100% { transform: rotate(0deg); }
	}
</style>
